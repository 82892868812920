<template>
  <modalContent
    name="case-files-manager"
    ref="case-files-manager"
    title="Case Files"
    width="50%"
    close
    :showFooter="false"
    @closed="onClose"
  >
    <template #content>
      <div class="file-manager-area p-4 rounded-2xl bg-gray-100 overflow-hidden" style="height: 500px">
        <div class="flex flex-col gap-4 h-full relative">
            <div class="h-full" :style="selectedFiles?.length ? 'height: calc(100% - 50px)': ''">
                <file-manager
                    ref="file-manager"
                    v-bind="fileManagerProps"
                    @on-selected-rows-change="selectAttachments($event)"
                    @on-tab-change="onFileManagerTabChange($event)"
                    @on-file-open="onFileOpen($event)" />
            </div>
            <div class="actions flex gap-4 w-full absolute bottom-0" v-if="selectedFiles?.length">
                <span>
                    Selected files ({{ selectedFiles.length }}): {{ selectedFiles.map((file) => file.name).join(',') }}
                </span>
                <del-button class="ml-auto" text="Add" type="primary" @click="onSelectFiles" />
            </div>
        </div>
      </div>
    </template>
  </modalContent>
</template>

<script>
const modalContent = () => import("@shared/modal-content");
const fileManager = () => import("@shared/dashboard-components/fileManager");
import DelButton from "@shared/components/button";
import axios from "@/axios";

export default {
  name: "Case-Files-Manager",
  props: {},
  data() {
    return {
        fileManagerProps: {
            tabs: [
                { key: 1, name: "Input", queryKey: "input" },
                { key: 2, name: "Internal", queryKey: "internal" },
                { key: 3, name: "Output", queryKey: "output" },
            ],
            selected: 1,
            showTabs: true,
            directories: [],
            isLoading: false,
            directory_type: "input",
            readOnly: true,
            enableSelection: true,
        },
        selectedFiles: [],
    };
  },
  components: {
    modalContent,
    fileManager,
    DelButton,
  },
  methods: {
    showModal() {
      this.$refs["case-files-manager"].showModal();
      this.selectedFiles = [];
      this.fileManagerProps.selected = 1;
      this.init(null, "input");
    },
    onClose() {
      this.$refs["case-files-manager"].hideModal();
    },
    async init(id, type = "input", checktype = null) {
        this.fileManagerProps.directory_type = type;
        this.fileManagerProps.directories = [];
        this.fileManagerProps.isLoading = true;
        this.fileManagerProps.directories = await this.fetchFiles(id, type, checktype);
        this.fileManagerProps.isLoading = false;
    },
    async fetchFiles(id, type, checkType) {
        const payload = {
            case_id: this.$route?.query?.caseid,
            directory_type: type,
        };
        if (id) {
            if (checkType === "referee") {
                payload.case_reference_id = id;
            } else {
                payload.check_id = id;
            }
            payload.directory_type = "input";
            this.$set(this.fileManagerProps, "selected", 1);
            this.$set(this.fileManagerProps, "tabs", [{ key: 1, name: "Input", queryKey: "input" }]);
        } else {
            this.$set(this.fileManagerProps, "tabs", [
                { key: 1, name: "Input", queryKey: "input" },
                { key: 2, name: "Internal", queryKey: "internal" },
                { key: 3, name: "Output", queryKey: "output" }
            ]);
        }
        try {
            const { data } = await axios.post(`/case-file-manager/tenant/get-file-data`, payload);
            return data?.data ?? [];
        } catch (error) {
            console.log(error, "[+] error while fetching files");
        }
    },
    onFileManagerTabChange(tab) {
        this.$refs?.["file-manager"]?.resetState();
        this.$set(this.fileManagerProps, "selected", tab.key);
        this.init(null, tab.queryKey);
    },
    async onFileOpen(details) {
        this.$set(this.fileManagerProps, "isLoading", true);
        try {
            const { data } = await axios.get(`/case-file-manager/get-file-url/${details.file_id}`);
            window.open(data.url, "_blank");
        } catch (error) {
            console.log(error, "[+] failed to fetch file");
        }
        this.$set(this.fileManagerProps, "isLoading", false);
    },
    selectAttachments(event) {
        this.selectedFiles = event;
    },
    async onSelectFiles() {
        const is_directory_selected = this.selectedFiles.filter((el) => el.type === 'directory')?.length;
        if (is_directory_selected) {
            this.$toast.error("Folders can't be attached to email")
            return;
        }
        let files = [];
        for (let i=0; i< this.selectedFiles.length; i++) {
            const arrayBuffer = await axios.get(`/case-file-manager/get-file-url/${this.selectedFiles[i].file_id}`, {
                responseType: "arraybuffer",
            });
            // console.log(data);

            // const arrayBuffer = req.response; // Note: not req.responseText
            if (arrayBuffer) {
                const byteArray = new Uint8Array(arrayBuffer);
                files.push(new File([byteArray], this.selectedFiles[i].name, {
                    type:"image/png",
                    size: this.selectedFiles[i].size,
                }));
            }
        }
        this.$emit("upload:caseFiles",files);
        this.onClose();
    },
    // async dataURLtoFile() {
    // //   var arr = dataurl.split(','),
    // //     mime = arr[0].match(/:(.*?);/)[1],
    // //     bstr = atob(arr[1]), 
    // //     n = bstr.length, 
    // //     u8arr = new Uint8Array(n);
          
    // //   while(n--){
    // //     u8arr[n] = bstr.charCodeAt(n);
    // //   }
    // //   return new File([u8arr], filename, {type:mime});

        

    //         console.log(files);
    //     return files;
    // }
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
</style>
